<template>
    <div class="carousel-me">
        <img :src="currentImage" alt="Carousel Image" class="img-slider" />
        <div class="indicators">
            <div v-for="(image, index) in images" :key="index" :class="{ active: index === currentIndex }"
                @click="jumpToIndex(index)"></div>
        </div>
        <a class="carousel-control-prev" role="button" @click="previous">
            <a-icon class="custom-prev" type="left" theme="outlined" />
        </a>
        <a class="carousel-control-next" role="button" @click="next">
            <a-icon class="custom-prev" type="right" theme="outlined" />
        </a>
    </div>
</template>
  
<script>
export default {
    props: {
        images: {
            type: Array,
            required: true
        },
        autoplayInterval: {
            type: Number,
            default: 5000
        }
    },
    data() {
        return {
            currentIndex: 0,
            isAutoplaying: false,
            autoplayTimerId: null,            
        };
    },
    computed: {
        currentImage() {
            return this.images[this.currentIndex];
        }
    },
    created() {
        this.startAutoplay();
    },
    destroyed() {
        this.stopAutoplay();
    },
    methods: {
        jumpToIndex(index) {
            this.currentIndex = index;
            this.stopAutoplay();
            this.startAutoplay();
        },
        startAutoplay() {
            if (this.autoplayInterval > 0 && !this.isAutoplaying) {
                this.isAutoplaying = true;
                this.autoplayTimerId = setInterval(() => {
                    this.currentIndex = (this.currentIndex + 1) % this.images.length;
                }, this.autoplayInterval);
            }
        },
        stopAutoplay() {
            if (this.isAutoplaying) {
                this.isAutoplaying = false;
                clearInterval(this.autoplayTimerId);
                this.autoplayTimerId = null;
            }
        },
        previous() {
            this.currentIndex = (this.currentIndex + this.images.length - 1) % this.images.length;
        },
        next() {
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
        }
    }
}
</script>
  
<style scoped>
.carousel-me {
    background-color: var(--primary-color);
    position: relative;
    width: 100%;
    padding-bottom: 38.25%;
    overflow: hidden;
}

.carousel-me img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
}

.indicators {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    padding-top: 10px;
    padding-bottom: 10px;
}

.indicators>div {
    border: 2px solid #fff;
    width: 16px;
    height: 16px;
    margin: 0 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.indicators>div.active {
    background-color: #fff;
}

.carousel-me:hover img {
    opacity: 0.8;
}

.img-slider {
    width: 100%;
}


/* botones  */
.carousel-control-prev {
    position: absolute;
    top: 40%;
    left: 50px;
    z-index: 1;
    color: #fff;
    font-size: 45px;
    outline: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(0,0,0,.5);
}
.custom-prev{
    text-shadow: 1px 1px 4px rgba(0,0,0,.4);
    position: relative;
    right: -5px;
    top: -5px;
    
}
.carousel-control-prev:hover, .carousel-control-next:hover {
    background: rgba(0,0,0,.8)!important;
    border: 0.7px groove #fff!important;
}
@media(max-width:700px){
    .carousel-control-prev{
        display: none;
    }
    .carousel-control-next{
        display: none;
    }
    
}


.carousel-control-next{
    position: absolute;
    top: 40%;
    right: 50px;
    z-index: 1;
    color: #fff;
    font-size: 45px;
    outline: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(0,0,0,.5);
}



</style>
  