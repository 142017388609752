<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <SliderDefault v-if="!isLoading" :images="this.images" :autoplayInterval="5000"></SliderDefault>
        <a-row v-if="!isLoading" type="flex" justify="center">
            <a-col class="a-col-mb" style="margin-top:  50px;">
                <h2 class="title-inicio"> PAGA AQUÍ TU FACTURA</h2>
                <a @click="showModalInfo()" class="btn-pse"></a>
            </a-col>
        </a-row>

        <!-- acceso rapido-->
        <a-row v-if="!isLoading" type="flex" justify="center" style="margin-bottom: 30px;">
            <a-col class="a-col-mb">

                <a-button @click="showModalInfo()" class="ax-shortcuts-link naranja">
                    <img class="ax-shortcuts-image lazyloaded"
                        src="https://cu.epm.com.co/Portals/clientes_y_usuarios/Images/uno.svg?ver=xFPzbRHeP8g9EzjAmQxmpw%3d%3d">
                    <span class=" accesos-text">
                        <strong>Paga tu factura</strong>
                        <br>
                    </span>
                </a-button>
            </a-col>
            <a-col class="a-col-mb">
                <router-link to="/publicTenders" title="Acceso a Contrataciones " class="ax-shortcuts-link verde">

                    <img class="ax-shortcuts-image lazyloaded"
                        src="https://cu.epm.com.co/Portals/clientes_y_usuarios/Images/dos.svg?ver=sAk3YUi2PamRUAmozK14NA%3d%3d">
                    <span class=" accesos-text">
                        <strong>Contrataciones</strong>
                        <br>
                    </span>


                </router-link>
            </a-col>

            <a-col class="a-col-mb">
                <router-link to="/publicPqrs" title="Acceso a Paga tu factura " class="ax-shortcuts-link verde">

                    <img class="ax-shortcuts-image lazyloaded"
                        src="https://cu.epm.com.co/Portals/clientes_y_usuarios/Images/dos.svg?ver=sAk3YUi2PamRUAmozK14NA%3d%3d">

                    <span class=" accesos-text">
                        <strong>Peticiones, quejas, reclamos y sugerencias</strong>
                        <br>
                    </span>

                </router-link>
            </a-col>

            <a-col class="a-col-mb">
                <a href="https://firebasestorage.googleapis.com/v0/b/emsilvia-ded00.appspot.com/o/politicaDatos%20personales%2FPOLITICA%20DE%20TRATAMIENTO%20DE%20DATOS%20EMSILVIA%20ESP.pdf?alt=media&token=b15d0105-b9a8-45ff-a854-3cef167c0801"
                    title="Acceso a Paga tu factura " class="ax-shortcuts-link verde" target="_blank">

                    <img class="ax-shortcuts-image lazyloaded"
                        src="https://cu.epm.com.co/Portals/clientes_y_usuarios/Images/dos.svg?ver=sAk3YUi2PamRUAmozK14NA%3d%3d">

                    <span class=" accesos-text">
                        <strong>Política Tratamiento de datos personales</strong>
                        <br>
                    </span>

                </a>
            </a-col>
        </a-row>

        <a-col v-if="!isLoading" class="a-col-mb" align="center">
            <h2 class="title-inicio">
                AQUÍ ESTAMOS PARA TI </h2>
            <p class="text-sub">
                Tenemos múltiples canales para atenderte, te esperamos. </p>
        </a-col>


        <!-- Contactos -->
        <a-row v-if="!isLoading" type="flex" justify="center" class="a-row-contact">
            <a-col :span="24" :lg="12" :sm="12" :xl="6" class="a-col-contact-left">
                <img src="https://urbaser.co/wp-content/themes/UrbaserColombia/img/title-sac.svg" alt="" class="bcc-title">
                <a-col>
                    <div class="box-contacto-text local">
                        <span></span>
                        <div>
                            Centro de Atención al Cliente:<br>
                            <b>Cra: 3a No.15-02 B/ San Agustin, Colina de Belen</b>
                        </div>
                    </div>
                    <div class="box-contacto-text horarios">
                        <span></span>
                        <div>
                            Horarios de atención<br>
                            <b>Lunes a viernes<br>
                                7:00 a. m. - 3:00 p. m. Jornada continua</b>
                        </div>
                    </div>
                    <div class="box-contacto-text">
                        <span></span>
                        <div>
                            <b><a href="mailto:emsilviaesp@gmail.com">emsilviaesp@gmail.com</a></b>
                        </div>
                    </div>
                </a-col>
            </a-col>

            <a-col :span="24" :lg="6" :sm="8" :xl="6" class="a-col-contact-right">
                <div class="foto-cac">

                </div>
            </a-col>
        </a-row>


        <!-- Acualidad, Noticias -->
        <a-col v-if="!isLoading" class="a-col-new">
            <h2 class="title-inicio title-col-new">
                LA TRANSFORMACIÓN SE HACE DÍA A DÍA </h2>
            <div class="desc-col-new">
                <p>
                    Conoce las diferentes actividades que realizamos en el pueblo y entérate de los próximos eventos
                    que estaremos realizando para que hagas parte de ellos.
                </p>
            </div>
        </a-col>


        <a-row v-if="!isLoading" class="container-blog-box" type="flex" justify="center">
            <a-col :lg:="6" :sm="11" :md="8" :xl="7" class="a-col-new-dash mt-mb-4" v-for="(item, index) in listNewsRandom"
                :key="index">
                <div class="blog-box-img">
                    <img :src="item.images[0].urlImage" alt="">
                </div>
                <div class="blog-box-text">
                    <div class="blog-date text-blue">
                        <ul class="post-categories">
                            <li>
                                <a href="" rel="category tag">EMSILVIA</a>
                            </li>
                        </ul>
                        <time datetime="2022">{{ item.date }}</time>

                    </div>
                    <h3 class="text-dubtitle">
                        <router-link class="text-blue" :to="{ name: 'publicDetailNew', params: { _id: item._id } }">{{
                            item.title
                        }}</router-link>

                    </h3>
                </div>
            </a-col>
        </a-row>

        <a-modal v-model="visibleModalInfo" title="Información importante" @ok="handleOk">
            <p>Le recordamos que este pago está destinado exclusivamente a cubrir el importe total de la factura. No se
                trata de un abono ni de ninguna otra transacción adicional. Por favor, le solicitamos amablemente que
                realice el pago únicamente por el monto indicado en la factura. Agradecemos sinceramente su colaboración.
            </p>

        </a-modal>
    </div>
</template>

<script>
import { db } from '../../firebaseConfig';
import SliderDefault from '../components/sliders/SliderDefault.vue';
import LoaderDefault from '../components/Loaders/LoaderDefault'
import { Modal } from 'ant-design-vue';

export default ({
    components: {
        SliderDefault,
        LoaderDefault,
        Modal
    },
    data() {
        return {
            images: [],
            showAnimation: false,
            listAllNews: [],
            isLoading: false,
            visibleModalInfo: false,
            listNewsRandom: [],
            listImages: [],
        }
    },
    mounted() {
        this.getAllNews()
        this.getImagesSlider()
    },
    methods: {
        showModalInfo() {
            this.visibleModalInfo = true;
        },
        handleOk() {
            window.location.href = 'https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=11523';
            this.visibleModalInfo = false;
        },
        async getAllNews() {
            this.isLoading = true;
            await db.collection('news').get().then((res) => {
                const news = [];
                res.forEach((doc) => {
                    news.push(doc.data());
                });
                this.listAllNews = news;
                const listaCopia = this.listAllNews.slice();
                if (listaCopia.length <= 3) {
                    this.listNewsRandom = [...listaCopia];
                } else {
                    this.listNewsRandom = listaCopia.sort(() => Math.random() - 0.5).slice(0, 3);
                }
                this.isLoading = false;
            });
        },
        async getImagesSlider() {
            this.listImages = []
            const result = await db.collection("imagesSlider").get()
            result.forEach(image => {
                const images = image.data()
                this.images.push(images.urlImage)
            });            
        },
    }
}
)

</script>

<style lang="css">

/* paga tu factrura */

.btn-pse {
    width: 350px;
    height: 121px;
    background-image: url('../assets/img/Buttom-pse.png');
    display: block;
    margin: 54px auto;
}

/*acceso rapido */

.ax-shortcuts-link.naranja {
    background: #bc5920;
    color: #fff;
}


.ax-shortcuts-link {
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    width: 200px;
    height: 124px;
    border-radius: 20px;
    transition: 0.6s;
    margin: 7px;
}

.ax-shortcuts-link.naranja img {
    border: 5px solid #bc5920;
    border-radius: 500px;
}

.ax-shortcuts-image {
    max-width: 94px;
    position: absolute;
    margin-top: -120px;
}

.accesos-text {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-top: 21px;
}

strong {
    font-weight: bold;
    word-break: break-word;
}

.ax-shortcuts-link.verde {
    background: var(--colorGreenPrimary);
    color: white;
}

.ax-shortcuts-link.verde img {
    border: 5px solid #7cb92a;
    border-radius: 500px;
}

.ax-shortcuts-link:hover img {
    box-shadow: 0px 6px 15px 5px rgb(0 0 0 / 25%);

}

/* Acualidad, Noticias */
.a-col-new {
    padding-left: 50px;
    padding-right: 50px;
}

.text-blue {
    color: white;
}

.text-blue:hover {
    color: white;
    font-weight: 700px;
}


.div-a-col-new {
    width: 38% !important;
}

.blog-date time {
    color: white;
}


.title-col-new {
    width: 700px;
}

.desc-col-new {
    width: 750px;
}

.container-blog-box {
    display: flex;
    justify-content: space-evenly;
}

.a-col-new-dash {
    overflow: hidden;
    border-radius: 16px;
    box-shadow: rgb(156, 155, 155) 4px 4px 18px 1px;
}

.a-col-new-dash:first-child {
    background-color: #87a9cd;
}

.a-col-new-dash {
    background-color: #8cb56d;
}

.a-col-new-dash:last-child {
    background-color: #18214b;
    color: #FFFFFF;
}

.blog-box-text {
    margin: 10px 10px 10px 10px;
}

.blog-date {
    font-family: 'helveticabold';
    font-size: 11px;
    line-height: 1;
}

ul.post-categories {
    padding-left: 0;
}

.post-categories {
    display: inline-block;
    margin-right: 8px;
    margin-top: 0;
    margin-bottom: 1rem;
}

.post-categories li {
    list-style: none;
    text-transform: uppercase;
}

.post-categories li a {
    color: white;
}

.text-dubtitle {
    font-size: 22px;
    line-height: 1.2;
    font-family: inherit;
    font-weight: 500;
    color: inherit;
    height: 100px;
    cursor: pointer;

}

/*Contactos */
img.bcc-title {
    width: 280px;
    position: absolute;
    top: -38px;
    left: 66px;
}

.box-contacto-text {
    text-align: left;
    margin: 12px 0;
    padding-left: 20px;
}

.foto-cac {
    height: 100%;
    border-radius: 0 16px 16px 0;
    background-image: url('../assets/img/catencionCliente1.png');
    background-position: center;
    background-size: cover;
}

.box-contacto-text.local span {
    background-image: url('../assets/img/icon-location-contacto.svg');
}

.box-contacto-text span {
    width: 26px;
    height: 26px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    background-image: url('../assets/img/icon-mail-contactos.svg');
    top: -2px;
    background-position: center;
    background-repeat: no-repeat;
}

.box-contacto-text.horarios span {
    background-image: url('../assets/img/icon-clock-contactos.svg');
}

.box-contacto-text div {
    width: calc(100% - 32px);
    padding-left: 4px;
    display: inline-block;
}

.a-row-contact {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
}

.a-col-contact-left {
    background-color: white;
    border-radius: 16px 0px 0px 16px;
}

.box-contacto-text:first-child {
    margin-top: 60px;
}


/* responsive contact */
@media (max-width: 600px) {
    .a-col-new-dash {
        min-width: 95%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .a-row-contact {
        padding-left: 10px;
        padding-right: 10px;
    }

    .a-col-contact-left {
        width: 100%;
        height: 240px;
        border-radius: 16px 16px 0px 0px;
    }

    .a-col-contact-right {
        width: 100%;
    }

    .foto-cac {
        height: 100%;
        border-radius: 0px 0px 16px 16px;
        height: 200px;
    }

    /* a-col-news */
    .a-col-new {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* responsive news */
@media (max-width: 900px) {
    .title-col-new {
        width: 100%;
    }

    .desc-col-new {
        width: 100%;
    }
}
</style>